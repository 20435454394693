<template>
  <a-modal
    title="新建版本信息"
    :visible="visible"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="版本">
        <a-input
          v-decorator="['tagsName', { rules: [{ required: true, message: '请输入版本!' }] }]"
        />
      </a-form-item>
      <a-form-item label="更新记录" style="margin-bottom: 0;">
        <a-input
          v-decorator="['tags', { rules: [{ required: true, message: '请输入更新记录!' }] }]"
          v-show="false"
        />
        <div class="flex" v-for="(tag, index) in tags" :key="index">
          <div class="flex-1" :class="{ noMargin: tags.length === 1 }">
            <a-input v-model="tag.value" @blur="handleBlur"></a-input>
          </div>
          <div class="icons" v-if="tags.length > 1" @click="del(index)">
            <a-icon type="close" />
          </div>
        </div>
      </a-form-item>
      <a-button icon="plus" type="link" @click="add">
        添加
      </a-button>
    </a-form>
  </a-modal>
</template>

<script>
  import { versionAdd } from '@/api/modular/fileStream/helpCenter'
  export default {
    data() {
      return {
        visible: false,
        form: this.$form.createForm(this, { name: 'coordinated' }),
        tags: [{ value: '' }]
      }
    },
    methods: {
      handleCancel() {
        this.tags = [{ value: '' }]
        this.form.resetFields()
        this.visible = false
      },
      open() {
        this.visible = true
      },
      add() {
        this.tags.push({ value: '' })
      },
      handleBlur() {
        this.form.setFieldsValue({ tags: this.tags.map(j => j.value).join() })
      },
      handleSubmit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values)
            versionAdd({
              'contentList': this.tags.map(j => j.value),
              'title': values.tagsName
            })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.$emit('success')
                this.handleCancel()
              }
            })
          }
        })
      },
      del(index) {
        this.tags.splice(index, 1)
      }
    }
  }
</script>

<style lang="less" scoped>
.flex-1 {
  flex: 1;
  margin-right: 15px;
  &.noMargin {
    margin-right: 0;
  }
}
.icons {
  font-size: 16px;
  cursor: pointer;
}
</style>
